@import "../fonts/Gotham/gotham.css";
@import "/node_modules/@splidejs/splide/dist/css/splide.min.css";
.leo-icon {
  display: block;
  width: 20px;
  height: 20px;
}

.leo-icon.leo-plus {
  background-image: url("../img/svgIcons/plus.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-minus {
  background-image: url("../img/svgIcons/minus.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-plus-square {
  background-image: url("../img/svgIcons/plus-square.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-minus-square {
  background-image: url("../img/svgIcons/minus-square.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-left {
  background-image: url("../img/svgIcons/left.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-right {
  background-image: url("../img/svgIcons/right.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-free-shipping {
  background-image: url("../img/svgIcons/free-shipping.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-nova-poshta {
  background-image: url("../img/svgIcons/nova-poshta.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_3 {
  background-image: url("../img/svgIcons/mono_3.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_5 {
  background-image: url("../img/svgIcons/mono_5.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_7 {
  background-image: url("../img/svgIcons/mono_7.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_10 {
  background-image: url("../img/svgIcons/mono_10.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-credit-card {
  background-image: url("../img/svgIcons/credit-card.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-show-more {
  background-image: url("../img/svgIcons/show-more.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-lg-img {
  background-image: url("../img/svgIcons/lg-img.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-minus-nb {
  background-image: url("../img/svgIcons/minus-nb.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-plus-nb {
  background-image: url("../img/svgIcons/plus-nb.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-arrow-left-dbl {
  background-image: url("../img/svgIcons/arrow-left-dbl.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-arrow-right-dbl {
  background-image: url("../img/svgIcons/arrow-right-dbl.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-arrow-down {
  background-image: url("../img/svgIcons/arrow-down.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-kyivstar {
  background-image: url("../img/svgIcons/kyivstar.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-life {
  background-image: url("../img/svgIcons/life.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-vodafone {
  background-image: url("../img/svgIcons/vodafone.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-arrow-right-3d-design {
  background-image: url("../img/svgIcons/arrow-right-3d-design.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-loader-leo {
  background-image: url("../img/svgIcons/loader-leo.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-three-tooths {
  background-image: url("../img/svgIcons/three-tooths.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-three-tooths-black {
  background-image: url("../img/svgIcons/three-tooths-black.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-user-icon {
  background-image: url("../img/svgIcons/user-icon.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-outlet-discount {
  background-image: url("../img/svgIcons/outlet-discount.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-payparts {
  background-image: url("../img/icons/payparts.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-microphone {
  background-image: url("../img/icons/microphone.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-visa-mastercard {
  background-image: url("../img/icons/visa-mastercard.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-visa-mastercard-2 {
  background-image: url("../img/icons/visa-mastercard-2.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-free-shipping-np {
  background-image: url("../img/icons/free-shipping-np.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-facebook_logo {
  background-image: url("../img/icons/facebook_logo.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-google_logo {
  background-image: url("../img/icons/google_logo.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-phone_orange_full {
  background-image: url("../img/icons/phone_orange_full.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-bask_n {
  background-image: url("../img/icons/bask_n.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-shopping-cart-full-leo {
  background-image: url("../img/icons/shopping-cart-full-leo.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-search_n {
  background-image: url("../img/icons/search_n.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-percent {
  background-image: url("../img/icons/percent.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-percent-orange {
  background-image: url("../img/icons/percent-orange.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-phone-mobile {
  background-image: url("../img/icons/phone-mobile.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-komunikatsiya_duz_klient {
  background-image: url("../img/icons/komunikatsiya_duz_klient.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-oplata_poslugu {
  background-image: url("../img/icons/oplata_poslugu.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-otrumannya_proektu {
  background-image: url("../img/icons/otrumannya_proektu.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-podannya_zayavku {
  background-image: url("../img/icons/podannya_zayavku.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-rozrobka_proektu {
  background-image: url("../img/icons/rozrobka_proektu.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-berezhemo_vashi_nervu {
  background-image: url("../img/icons/berezhemo_vashi_nervu.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-garantija_tcilisnisti_tovary {
  background-image: url("../img/icons/garantija_tcilisnisti_tovary.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-klientu_nam_dovirjaut {
  background-image: url("../img/icons/klientu_nam_dovirjaut.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-vchasna_dostavka {
  background-image: url("../img/icons/vchasna_dostavka.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-rokiv_16_na_runky {
  background-image: url("../img/icons/rokiv_16_na_runky.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-magazunu_33 {
  background-image: url("../img/icons/magazunu_33.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-induvidyalni_rishennya {
  background-image: url("../img/icons/induvidyalni_rishennya.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-million_klientiv {
  background-image: url("../img/icons/million_klientiv.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-naykrashi_tsinu {
  background-image: url("../img/icons/naykrashi_tsinu.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-profesijna_konsultatcia {
  background-image: url("../img/icons/profesijna_konsultatcia.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-download {
  background-image: url("../img/icons/download.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-tovar_v_najavnosti {
  background-image: url("../img/icons/tovar_v_najavnosti.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-velukuy_vubir {
  background-image: url("../img/icons/velukuy_vubir.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-lokatsija {
  background-image: url("../img/icons/lokatsija.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mail {
  background-image: url("../img/icons/mail.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-home_tm {
  background-image: url("../img/icons/home_tm.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-hot_offer {
  background-image: url("../img/icons/hot_offer.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-hot_offerl {
  background-image: url("../img/icons/hot_offerl.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-sale-board {
  background-image: url("../img/icons/sale-board.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-hot-item {
  background-image: url("../img/icons/hot-item.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_2 {
  background-image: url("../img/icons/mono_2.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_3 {
  background-image: url("../img/icons/mono_3.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_4 {
  background-image: url("../img/icons/mono_4.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_5 {
  background-image: url("../img/icons/mono_5.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_6 {
  background-image: url("../img/icons/mono_6.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_7 {
  background-image: url("../img/icons/mono_7.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-mono_8 {
  background-image: url("../img/icons/mono_8.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-outlet {
  background-image: url("../img/icons/outlet.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-chats_new {
  background-image: url("../img/icons/chats_new.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-ep_arrow-up {
  background-image: url("../img/icons/ep_arrow-up.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.leo-down_arrow {
  background-image: url("../img/icons/down_arrow.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.leo-icon.s-1 {
  width: 1px !important;
  height: 1px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-1 {
    width: 1px !important;
    height: 1px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-1 {
    width: 1px !important;
    height: 1px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-1 {
    width: 1px !important;
    height: 1px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-1 {
    width: 1px !important;
    height: 1px !important;
  }
}
.leo-icon.s-2 {
  width: 2px !important;
  height: 2px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-2 {
    width: 2px !important;
    height: 2px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-2 {
    width: 2px !important;
    height: 2px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-2 {
    width: 2px !important;
    height: 2px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-2 {
    width: 2px !important;
    height: 2px !important;
  }
}
.leo-icon.s-3 {
  width: 3px !important;
  height: 3px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-3 {
    width: 3px !important;
    height: 3px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-3 {
    width: 3px !important;
    height: 3px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-3 {
    width: 3px !important;
    height: 3px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-3 {
    width: 3px !important;
    height: 3px !important;
  }
}
.leo-icon.s-4 {
  width: 4px !important;
  height: 4px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-4 {
    width: 4px !important;
    height: 4px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-4 {
    width: 4px !important;
    height: 4px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-4 {
    width: 4px !important;
    height: 4px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-4 {
    width: 4px !important;
    height: 4px !important;
  }
}
.leo-icon.s-5 {
  width: 5px !important;
  height: 5px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-5 {
    width: 5px !important;
    height: 5px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-5 {
    width: 5px !important;
    height: 5px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-5 {
    width: 5px !important;
    height: 5px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-5 {
    width: 5px !important;
    height: 5px !important;
  }
}
.leo-icon.s-6 {
  width: 6px !important;
  height: 6px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-6 {
    width: 6px !important;
    height: 6px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-6 {
    width: 6px !important;
    height: 6px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-6 {
    width: 6px !important;
    height: 6px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-6 {
    width: 6px !important;
    height: 6px !important;
  }
}
.leo-icon.s-7 {
  width: 7px !important;
  height: 7px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-7 {
    width: 7px !important;
    height: 7px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-7 {
    width: 7px !important;
    height: 7px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-7 {
    width: 7px !important;
    height: 7px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-7 {
    width: 7px !important;
    height: 7px !important;
  }
}
.leo-icon.s-8 {
  width: 8px !important;
  height: 8px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-8 {
    width: 8px !important;
    height: 8px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-8 {
    width: 8px !important;
    height: 8px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-8 {
    width: 8px !important;
    height: 8px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-8 {
    width: 8px !important;
    height: 8px !important;
  }
}
.leo-icon.s-9 {
  width: 9px !important;
  height: 9px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-9 {
    width: 9px !important;
    height: 9px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-9 {
    width: 9px !important;
    height: 9px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-9 {
    width: 9px !important;
    height: 9px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-9 {
    width: 9px !important;
    height: 9px !important;
  }
}
.leo-icon.s-10 {
  width: 10px !important;
  height: 10px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-10 {
    width: 10px !important;
    height: 10px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-10 {
    width: 10px !important;
    height: 10px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-10 {
    width: 10px !important;
    height: 10px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-10 {
    width: 10px !important;
    height: 10px !important;
  }
}
.leo-icon.s-11 {
  width: 11px !important;
  height: 11px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-11 {
    width: 11px !important;
    height: 11px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-11 {
    width: 11px !important;
    height: 11px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-11 {
    width: 11px !important;
    height: 11px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-11 {
    width: 11px !important;
    height: 11px !important;
  }
}
.leo-icon.s-12 {
  width: 12px !important;
  height: 12px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-12 {
    width: 12px !important;
    height: 12px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-12 {
    width: 12px !important;
    height: 12px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-12 {
    width: 12px !important;
    height: 12px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-12 {
    width: 12px !important;
    height: 12px !important;
  }
}
.leo-icon.s-13 {
  width: 13px !important;
  height: 13px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-13 {
    width: 13px !important;
    height: 13px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-13 {
    width: 13px !important;
    height: 13px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-13 {
    width: 13px !important;
    height: 13px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-13 {
    width: 13px !important;
    height: 13px !important;
  }
}
.leo-icon.s-14 {
  width: 14px !important;
  height: 14px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-14 {
    width: 14px !important;
    height: 14px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-14 {
    width: 14px !important;
    height: 14px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-14 {
    width: 14px !important;
    height: 14px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-14 {
    width: 14px !important;
    height: 14px !important;
  }
}
.leo-icon.s-15 {
  width: 15px !important;
  height: 15px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-15 {
    width: 15px !important;
    height: 15px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-15 {
    width: 15px !important;
    height: 15px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-15 {
    width: 15px !important;
    height: 15px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-15 {
    width: 15px !important;
    height: 15px !important;
  }
}
.leo-icon.s-16 {
  width: 16px !important;
  height: 16px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-16 {
    width: 16px !important;
    height: 16px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-16 {
    width: 16px !important;
    height: 16px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-16 {
    width: 16px !important;
    height: 16px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-16 {
    width: 16px !important;
    height: 16px !important;
  }
}
.leo-icon.s-17 {
  width: 17px !important;
  height: 17px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-17 {
    width: 17px !important;
    height: 17px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-17 {
    width: 17px !important;
    height: 17px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-17 {
    width: 17px !important;
    height: 17px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-17 {
    width: 17px !important;
    height: 17px !important;
  }
}
.leo-icon.s-18 {
  width: 18px !important;
  height: 18px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-18 {
    width: 18px !important;
    height: 18px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-18 {
    width: 18px !important;
    height: 18px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-18 {
    width: 18px !important;
    height: 18px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-18 {
    width: 18px !important;
    height: 18px !important;
  }
}
.leo-icon.s-19 {
  width: 19px !important;
  height: 19px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-19 {
    width: 19px !important;
    height: 19px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-19 {
    width: 19px !important;
    height: 19px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-19 {
    width: 19px !important;
    height: 19px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-19 {
    width: 19px !important;
    height: 19px !important;
  }
}
.leo-icon.s-20 {
  width: 20px !important;
  height: 20px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-20 {
    width: 20px !important;
    height: 20px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-20 {
    width: 20px !important;
    height: 20px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-20 {
    width: 20px !important;
    height: 20px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-20 {
    width: 20px !important;
    height: 20px !important;
  }
}
.leo-icon.s-21 {
  width: 21px !important;
  height: 21px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-21 {
    width: 21px !important;
    height: 21px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-21 {
    width: 21px !important;
    height: 21px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-21 {
    width: 21px !important;
    height: 21px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-21 {
    width: 21px !important;
    height: 21px !important;
  }
}
.leo-icon.s-22 {
  width: 22px !important;
  height: 22px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-22 {
    width: 22px !important;
    height: 22px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-22 {
    width: 22px !important;
    height: 22px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-22 {
    width: 22px !important;
    height: 22px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-22 {
    width: 22px !important;
    height: 22px !important;
  }
}
.leo-icon.s-23 {
  width: 23px !important;
  height: 23px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-23 {
    width: 23px !important;
    height: 23px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-23 {
    width: 23px !important;
    height: 23px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-23 {
    width: 23px !important;
    height: 23px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-23 {
    width: 23px !important;
    height: 23px !important;
  }
}
.leo-icon.s-24 {
  width: 24px !important;
  height: 24px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-24 {
    width: 24px !important;
    height: 24px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-24 {
    width: 24px !important;
    height: 24px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-24 {
    width: 24px !important;
    height: 24px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-24 {
    width: 24px !important;
    height: 24px !important;
  }
}
.leo-icon.s-25 {
  width: 25px !important;
  height: 25px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-25 {
    width: 25px !important;
    height: 25px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-25 {
    width: 25px !important;
    height: 25px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-25 {
    width: 25px !important;
    height: 25px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-25 {
    width: 25px !important;
    height: 25px !important;
  }
}
.leo-icon.s-26 {
  width: 26px !important;
  height: 26px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-26 {
    width: 26px !important;
    height: 26px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-26 {
    width: 26px !important;
    height: 26px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-26 {
    width: 26px !important;
    height: 26px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-26 {
    width: 26px !important;
    height: 26px !important;
  }
}
.leo-icon.s-27 {
  width: 27px !important;
  height: 27px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-27 {
    width: 27px !important;
    height: 27px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-27 {
    width: 27px !important;
    height: 27px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-27 {
    width: 27px !important;
    height: 27px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-27 {
    width: 27px !important;
    height: 27px !important;
  }
}
.leo-icon.s-28 {
  width: 28px !important;
  height: 28px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-28 {
    width: 28px !important;
    height: 28px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-28 {
    width: 28px !important;
    height: 28px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-28 {
    width: 28px !important;
    height: 28px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-28 {
    width: 28px !important;
    height: 28px !important;
  }
}
.leo-icon.s-29 {
  width: 29px !important;
  height: 29px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-29 {
    width: 29px !important;
    height: 29px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-29 {
    width: 29px !important;
    height: 29px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-29 {
    width: 29px !important;
    height: 29px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-29 {
    width: 29px !important;
    height: 29px !important;
  }
}
.leo-icon.s-30 {
  width: 30px !important;
  height: 30px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-30 {
    width: 30px !important;
    height: 30px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-30 {
    width: 30px !important;
    height: 30px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-30 {
    width: 30px !important;
    height: 30px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-30 {
    width: 30px !important;
    height: 30px !important;
  }
}
.leo-icon.s-31 {
  width: 31px !important;
  height: 31px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-31 {
    width: 31px !important;
    height: 31px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-31 {
    width: 31px !important;
    height: 31px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-31 {
    width: 31px !important;
    height: 31px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-31 {
    width: 31px !important;
    height: 31px !important;
  }
}
.leo-icon.s-32 {
  width: 32px !important;
  height: 32px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-32 {
    width: 32px !important;
    height: 32px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-32 {
    width: 32px !important;
    height: 32px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-32 {
    width: 32px !important;
    height: 32px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-32 {
    width: 32px !important;
    height: 32px !important;
  }
}
.leo-icon.s-33 {
  width: 33px !important;
  height: 33px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-33 {
    width: 33px !important;
    height: 33px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-33 {
    width: 33px !important;
    height: 33px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-33 {
    width: 33px !important;
    height: 33px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-33 {
    width: 33px !important;
    height: 33px !important;
  }
}
.leo-icon.s-34 {
  width: 34px !important;
  height: 34px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-34 {
    width: 34px !important;
    height: 34px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-34 {
    width: 34px !important;
    height: 34px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-34 {
    width: 34px !important;
    height: 34px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-34 {
    width: 34px !important;
    height: 34px !important;
  }
}
.leo-icon.s-35 {
  width: 35px !important;
  height: 35px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-35 {
    width: 35px !important;
    height: 35px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-35 {
    width: 35px !important;
    height: 35px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-35 {
    width: 35px !important;
    height: 35px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-35 {
    width: 35px !important;
    height: 35px !important;
  }
}
.leo-icon.s-36 {
  width: 36px !important;
  height: 36px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-36 {
    width: 36px !important;
    height: 36px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-36 {
    width: 36px !important;
    height: 36px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-36 {
    width: 36px !important;
    height: 36px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-36 {
    width: 36px !important;
    height: 36px !important;
  }
}
.leo-icon.s-37 {
  width: 37px !important;
  height: 37px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-37 {
    width: 37px !important;
    height: 37px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-37 {
    width: 37px !important;
    height: 37px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-37 {
    width: 37px !important;
    height: 37px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-37 {
    width: 37px !important;
    height: 37px !important;
  }
}
.leo-icon.s-38 {
  width: 38px !important;
  height: 38px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-38 {
    width: 38px !important;
    height: 38px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-38 {
    width: 38px !important;
    height: 38px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-38 {
    width: 38px !important;
    height: 38px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-38 {
    width: 38px !important;
    height: 38px !important;
  }
}
.leo-icon.s-39 {
  width: 39px !important;
  height: 39px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-39 {
    width: 39px !important;
    height: 39px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-39 {
    width: 39px !important;
    height: 39px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-39 {
    width: 39px !important;
    height: 39px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-39 {
    width: 39px !important;
    height: 39px !important;
  }
}
.leo-icon.s-40 {
  width: 40px !important;
  height: 40px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-40 {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-40 {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-40 {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-40 {
    width: 40px !important;
    height: 40px !important;
  }
}
.leo-icon.s-41 {
  width: 41px !important;
  height: 41px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-41 {
    width: 41px !important;
    height: 41px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-41 {
    width: 41px !important;
    height: 41px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-41 {
    width: 41px !important;
    height: 41px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-41 {
    width: 41px !important;
    height: 41px !important;
  }
}
.leo-icon.s-42 {
  width: 42px !important;
  height: 42px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-42 {
    width: 42px !important;
    height: 42px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-42 {
    width: 42px !important;
    height: 42px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-42 {
    width: 42px !important;
    height: 42px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-42 {
    width: 42px !important;
    height: 42px !important;
  }
}
.leo-icon.s-43 {
  width: 43px !important;
  height: 43px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-43 {
    width: 43px !important;
    height: 43px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-43 {
    width: 43px !important;
    height: 43px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-43 {
    width: 43px !important;
    height: 43px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-43 {
    width: 43px !important;
    height: 43px !important;
  }
}
.leo-icon.s-44 {
  width: 44px !important;
  height: 44px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-44 {
    width: 44px !important;
    height: 44px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-44 {
    width: 44px !important;
    height: 44px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-44 {
    width: 44px !important;
    height: 44px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-44 {
    width: 44px !important;
    height: 44px !important;
  }
}
.leo-icon.s-45 {
  width: 45px !important;
  height: 45px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-45 {
    width: 45px !important;
    height: 45px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-45 {
    width: 45px !important;
    height: 45px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-45 {
    width: 45px !important;
    height: 45px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-45 {
    width: 45px !important;
    height: 45px !important;
  }
}
.leo-icon.s-46 {
  width: 46px !important;
  height: 46px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-46 {
    width: 46px !important;
    height: 46px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-46 {
    width: 46px !important;
    height: 46px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-46 {
    width: 46px !important;
    height: 46px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-46 {
    width: 46px !important;
    height: 46px !important;
  }
}
.leo-icon.s-47 {
  width: 47px !important;
  height: 47px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-47 {
    width: 47px !important;
    height: 47px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-47 {
    width: 47px !important;
    height: 47px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-47 {
    width: 47px !important;
    height: 47px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-47 {
    width: 47px !important;
    height: 47px !important;
  }
}
.leo-icon.s-48 {
  width: 48px !important;
  height: 48px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-48 {
    width: 48px !important;
    height: 48px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-48 {
    width: 48px !important;
    height: 48px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-48 {
    width: 48px !important;
    height: 48px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-48 {
    width: 48px !important;
    height: 48px !important;
  }
}
.leo-icon.s-49 {
  width: 49px !important;
  height: 49px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-49 {
    width: 49px !important;
    height: 49px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-49 {
    width: 49px !important;
    height: 49px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-49 {
    width: 49px !important;
    height: 49px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-49 {
    width: 49px !important;
    height: 49px !important;
  }
}
.leo-icon.s-50 {
  width: 50px !important;
  height: 50px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-50 {
    width: 50px !important;
    height: 50px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-50 {
    width: 50px !important;
    height: 50px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-50 {
    width: 50px !important;
    height: 50px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-50 {
    width: 50px !important;
    height: 50px !important;
  }
}
.leo-icon.s-51 {
  width: 51px !important;
  height: 51px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-51 {
    width: 51px !important;
    height: 51px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-51 {
    width: 51px !important;
    height: 51px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-51 {
    width: 51px !important;
    height: 51px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-51 {
    width: 51px !important;
    height: 51px !important;
  }
}
.leo-icon.s-52 {
  width: 52px !important;
  height: 52px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-52 {
    width: 52px !important;
    height: 52px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-52 {
    width: 52px !important;
    height: 52px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-52 {
    width: 52px !important;
    height: 52px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-52 {
    width: 52px !important;
    height: 52px !important;
  }
}
.leo-icon.s-53 {
  width: 53px !important;
  height: 53px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-53 {
    width: 53px !important;
    height: 53px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-53 {
    width: 53px !important;
    height: 53px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-53 {
    width: 53px !important;
    height: 53px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-53 {
    width: 53px !important;
    height: 53px !important;
  }
}
.leo-icon.s-54 {
  width: 54px !important;
  height: 54px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-54 {
    width: 54px !important;
    height: 54px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-54 {
    width: 54px !important;
    height: 54px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-54 {
    width: 54px !important;
    height: 54px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-54 {
    width: 54px !important;
    height: 54px !important;
  }
}
.leo-icon.s-55 {
  width: 55px !important;
  height: 55px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-55 {
    width: 55px !important;
    height: 55px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-55 {
    width: 55px !important;
    height: 55px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-55 {
    width: 55px !important;
    height: 55px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-55 {
    width: 55px !important;
    height: 55px !important;
  }
}
.leo-icon.s-56 {
  width: 56px !important;
  height: 56px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-56 {
    width: 56px !important;
    height: 56px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-56 {
    width: 56px !important;
    height: 56px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-56 {
    width: 56px !important;
    height: 56px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-56 {
    width: 56px !important;
    height: 56px !important;
  }
}
.leo-icon.s-57 {
  width: 57px !important;
  height: 57px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-57 {
    width: 57px !important;
    height: 57px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-57 {
    width: 57px !important;
    height: 57px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-57 {
    width: 57px !important;
    height: 57px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-57 {
    width: 57px !important;
    height: 57px !important;
  }
}
.leo-icon.s-58 {
  width: 58px !important;
  height: 58px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-58 {
    width: 58px !important;
    height: 58px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-58 {
    width: 58px !important;
    height: 58px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-58 {
    width: 58px !important;
    height: 58px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-58 {
    width: 58px !important;
    height: 58px !important;
  }
}
.leo-icon.s-59 {
  width: 59px !important;
  height: 59px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-59 {
    width: 59px !important;
    height: 59px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-59 {
    width: 59px !important;
    height: 59px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-59 {
    width: 59px !important;
    height: 59px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-59 {
    width: 59px !important;
    height: 59px !important;
  }
}
.leo-icon.s-60 {
  width: 60px !important;
  height: 60px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-60 {
    width: 60px !important;
    height: 60px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-60 {
    width: 60px !important;
    height: 60px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-60 {
    width: 60px !important;
    height: 60px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-60 {
    width: 60px !important;
    height: 60px !important;
  }
}
.leo-icon.s-61 {
  width: 61px !important;
  height: 61px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-61 {
    width: 61px !important;
    height: 61px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-61 {
    width: 61px !important;
    height: 61px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-61 {
    width: 61px !important;
    height: 61px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-61 {
    width: 61px !important;
    height: 61px !important;
  }
}
.leo-icon.s-62 {
  width: 62px !important;
  height: 62px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-62 {
    width: 62px !important;
    height: 62px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-62 {
    width: 62px !important;
    height: 62px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-62 {
    width: 62px !important;
    height: 62px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-62 {
    width: 62px !important;
    height: 62px !important;
  }
}
.leo-icon.s-63 {
  width: 63px !important;
  height: 63px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-63 {
    width: 63px !important;
    height: 63px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-63 {
    width: 63px !important;
    height: 63px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-63 {
    width: 63px !important;
    height: 63px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-63 {
    width: 63px !important;
    height: 63px !important;
  }
}
.leo-icon.s-64 {
  width: 64px !important;
  height: 64px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-64 {
    width: 64px !important;
    height: 64px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-64 {
    width: 64px !important;
    height: 64px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-64 {
    width: 64px !important;
    height: 64px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-64 {
    width: 64px !important;
    height: 64px !important;
  }
}
.leo-icon.s-65 {
  width: 65px !important;
  height: 65px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-65 {
    width: 65px !important;
    height: 65px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-65 {
    width: 65px !important;
    height: 65px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-65 {
    width: 65px !important;
    height: 65px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-65 {
    width: 65px !important;
    height: 65px !important;
  }
}
.leo-icon.s-66 {
  width: 66px !important;
  height: 66px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-66 {
    width: 66px !important;
    height: 66px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-66 {
    width: 66px !important;
    height: 66px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-66 {
    width: 66px !important;
    height: 66px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-66 {
    width: 66px !important;
    height: 66px !important;
  }
}
.leo-icon.s-67 {
  width: 67px !important;
  height: 67px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-67 {
    width: 67px !important;
    height: 67px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-67 {
    width: 67px !important;
    height: 67px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-67 {
    width: 67px !important;
    height: 67px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-67 {
    width: 67px !important;
    height: 67px !important;
  }
}
.leo-icon.s-68 {
  width: 68px !important;
  height: 68px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-68 {
    width: 68px !important;
    height: 68px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-68 {
    width: 68px !important;
    height: 68px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-68 {
    width: 68px !important;
    height: 68px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-68 {
    width: 68px !important;
    height: 68px !important;
  }
}
.leo-icon.s-69 {
  width: 69px !important;
  height: 69px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-69 {
    width: 69px !important;
    height: 69px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-69 {
    width: 69px !important;
    height: 69px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-69 {
    width: 69px !important;
    height: 69px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-69 {
    width: 69px !important;
    height: 69px !important;
  }
}
.leo-icon.s-70 {
  width: 70px !important;
  height: 70px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-70 {
    width: 70px !important;
    height: 70px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-70 {
    width: 70px !important;
    height: 70px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-70 {
    width: 70px !important;
    height: 70px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-70 {
    width: 70px !important;
    height: 70px !important;
  }
}
.leo-icon.s-71 {
  width: 71px !important;
  height: 71px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-71 {
    width: 71px !important;
    height: 71px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-71 {
    width: 71px !important;
    height: 71px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-71 {
    width: 71px !important;
    height: 71px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-71 {
    width: 71px !important;
    height: 71px !important;
  }
}
.leo-icon.s-72 {
  width: 72px !important;
  height: 72px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-72 {
    width: 72px !important;
    height: 72px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-72 {
    width: 72px !important;
    height: 72px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-72 {
    width: 72px !important;
    height: 72px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-72 {
    width: 72px !important;
    height: 72px !important;
  }
}
.leo-icon.s-73 {
  width: 73px !important;
  height: 73px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-73 {
    width: 73px !important;
    height: 73px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-73 {
    width: 73px !important;
    height: 73px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-73 {
    width: 73px !important;
    height: 73px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-73 {
    width: 73px !important;
    height: 73px !important;
  }
}
.leo-icon.s-74 {
  width: 74px !important;
  height: 74px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-74 {
    width: 74px !important;
    height: 74px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-74 {
    width: 74px !important;
    height: 74px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-74 {
    width: 74px !important;
    height: 74px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-74 {
    width: 74px !important;
    height: 74px !important;
  }
}
.leo-icon.s-75 {
  width: 75px !important;
  height: 75px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-75 {
    width: 75px !important;
    height: 75px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-75 {
    width: 75px !important;
    height: 75px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-75 {
    width: 75px !important;
    height: 75px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-75 {
    width: 75px !important;
    height: 75px !important;
  }
}
.leo-icon.s-76 {
  width: 76px !important;
  height: 76px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-76 {
    width: 76px !important;
    height: 76px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-76 {
    width: 76px !important;
    height: 76px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-76 {
    width: 76px !important;
    height: 76px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-76 {
    width: 76px !important;
    height: 76px !important;
  }
}
.leo-icon.s-77 {
  width: 77px !important;
  height: 77px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-77 {
    width: 77px !important;
    height: 77px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-77 {
    width: 77px !important;
    height: 77px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-77 {
    width: 77px !important;
    height: 77px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-77 {
    width: 77px !important;
    height: 77px !important;
  }
}
.leo-icon.s-78 {
  width: 78px !important;
  height: 78px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-78 {
    width: 78px !important;
    height: 78px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-78 {
    width: 78px !important;
    height: 78px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-78 {
    width: 78px !important;
    height: 78px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-78 {
    width: 78px !important;
    height: 78px !important;
  }
}
.leo-icon.s-79 {
  width: 79px !important;
  height: 79px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-79 {
    width: 79px !important;
    height: 79px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-79 {
    width: 79px !important;
    height: 79px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-79 {
    width: 79px !important;
    height: 79px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-79 {
    width: 79px !important;
    height: 79px !important;
  }
}
.leo-icon.s-80 {
  width: 80px !important;
  height: 80px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-80 {
    width: 80px !important;
    height: 80px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-80 {
    width: 80px !important;
    height: 80px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-80 {
    width: 80px !important;
    height: 80px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-80 {
    width: 80px !important;
    height: 80px !important;
  }
}
.leo-icon.s-81 {
  width: 81px !important;
  height: 81px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-81 {
    width: 81px !important;
    height: 81px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-81 {
    width: 81px !important;
    height: 81px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-81 {
    width: 81px !important;
    height: 81px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-81 {
    width: 81px !important;
    height: 81px !important;
  }
}
.leo-icon.s-82 {
  width: 82px !important;
  height: 82px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-82 {
    width: 82px !important;
    height: 82px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-82 {
    width: 82px !important;
    height: 82px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-82 {
    width: 82px !important;
    height: 82px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-82 {
    width: 82px !important;
    height: 82px !important;
  }
}
.leo-icon.s-83 {
  width: 83px !important;
  height: 83px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-83 {
    width: 83px !important;
    height: 83px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-83 {
    width: 83px !important;
    height: 83px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-83 {
    width: 83px !important;
    height: 83px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-83 {
    width: 83px !important;
    height: 83px !important;
  }
}
.leo-icon.s-84 {
  width: 84px !important;
  height: 84px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-84 {
    width: 84px !important;
    height: 84px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-84 {
    width: 84px !important;
    height: 84px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-84 {
    width: 84px !important;
    height: 84px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-84 {
    width: 84px !important;
    height: 84px !important;
  }
}
.leo-icon.s-85 {
  width: 85px !important;
  height: 85px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-85 {
    width: 85px !important;
    height: 85px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-85 {
    width: 85px !important;
    height: 85px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-85 {
    width: 85px !important;
    height: 85px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-85 {
    width: 85px !important;
    height: 85px !important;
  }
}
.leo-icon.s-86 {
  width: 86px !important;
  height: 86px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-86 {
    width: 86px !important;
    height: 86px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-86 {
    width: 86px !important;
    height: 86px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-86 {
    width: 86px !important;
    height: 86px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-86 {
    width: 86px !important;
    height: 86px !important;
  }
}
.leo-icon.s-87 {
  width: 87px !important;
  height: 87px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-87 {
    width: 87px !important;
    height: 87px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-87 {
    width: 87px !important;
    height: 87px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-87 {
    width: 87px !important;
    height: 87px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-87 {
    width: 87px !important;
    height: 87px !important;
  }
}
.leo-icon.s-88 {
  width: 88px !important;
  height: 88px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-88 {
    width: 88px !important;
    height: 88px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-88 {
    width: 88px !important;
    height: 88px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-88 {
    width: 88px !important;
    height: 88px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-88 {
    width: 88px !important;
    height: 88px !important;
  }
}
.leo-icon.s-89 {
  width: 89px !important;
  height: 89px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-89 {
    width: 89px !important;
    height: 89px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-89 {
    width: 89px !important;
    height: 89px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-89 {
    width: 89px !important;
    height: 89px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-89 {
    width: 89px !important;
    height: 89px !important;
  }
}
.leo-icon.s-90 {
  width: 90px !important;
  height: 90px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-90 {
    width: 90px !important;
    height: 90px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-90 {
    width: 90px !important;
    height: 90px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-90 {
    width: 90px !important;
    height: 90px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-90 {
    width: 90px !important;
    height: 90px !important;
  }
}
.leo-icon.s-91 {
  width: 91px !important;
  height: 91px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-91 {
    width: 91px !important;
    height: 91px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-91 {
    width: 91px !important;
    height: 91px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-91 {
    width: 91px !important;
    height: 91px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-91 {
    width: 91px !important;
    height: 91px !important;
  }
}
.leo-icon.s-92 {
  width: 92px !important;
  height: 92px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-92 {
    width: 92px !important;
    height: 92px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-92 {
    width: 92px !important;
    height: 92px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-92 {
    width: 92px !important;
    height: 92px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-92 {
    width: 92px !important;
    height: 92px !important;
  }
}
.leo-icon.s-93 {
  width: 93px !important;
  height: 93px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-93 {
    width: 93px !important;
    height: 93px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-93 {
    width: 93px !important;
    height: 93px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-93 {
    width: 93px !important;
    height: 93px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-93 {
    width: 93px !important;
    height: 93px !important;
  }
}
.leo-icon.s-94 {
  width: 94px !important;
  height: 94px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-94 {
    width: 94px !important;
    height: 94px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-94 {
    width: 94px !important;
    height: 94px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-94 {
    width: 94px !important;
    height: 94px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-94 {
    width: 94px !important;
    height: 94px !important;
  }
}
.leo-icon.s-95 {
  width: 95px !important;
  height: 95px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-95 {
    width: 95px !important;
    height: 95px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-95 {
    width: 95px !important;
    height: 95px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-95 {
    width: 95px !important;
    height: 95px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-95 {
    width: 95px !important;
    height: 95px !important;
  }
}
.leo-icon.s-96 {
  width: 96px !important;
  height: 96px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-96 {
    width: 96px !important;
    height: 96px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-96 {
    width: 96px !important;
    height: 96px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-96 {
    width: 96px !important;
    height: 96px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-96 {
    width: 96px !important;
    height: 96px !important;
  }
}
.leo-icon.s-97 {
  width: 97px !important;
  height: 97px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-97 {
    width: 97px !important;
    height: 97px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-97 {
    width: 97px !important;
    height: 97px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-97 {
    width: 97px !important;
    height: 97px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-97 {
    width: 97px !important;
    height: 97px !important;
  }
}
.leo-icon.s-98 {
  width: 98px !important;
  height: 98px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-98 {
    width: 98px !important;
    height: 98px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-98 {
    width: 98px !important;
    height: 98px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-98 {
    width: 98px !important;
    height: 98px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-98 {
    width: 98px !important;
    height: 98px !important;
  }
}
.leo-icon.s-99 {
  width: 99px !important;
  height: 99px !important;
}

@media (max-width: 600px) {
  .leo-icon.s-sm-99 {
    width: 99px !important;
    height: 99px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-99 {
    width: 99px !important;
    height: 99px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-99 {
    width: 99px !important;
    height: 99px !important;
  }
}
@media (min-width: 1280px) {
  .leo-icon.s-xl-99 {
    width: 99px !important;
    height: 99px !important;
  }
}
.leo-spinner {
  animation: rotate 1s linear infinite;
}
.leo-spinner .path {
  stroke: hsl(210deg, 70%, 75%);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-21 {
  font-size: 21px;
}

.fs-22 {
  font-size: 22px;
}

.fs-23 {
  font-size: 23px;
}

.fs-24 {
  font-size: 24px;
}

.fs-25 {
  font-size: 25px;
}

.fs-26 {
  font-size: 26px;
}

.fs-27 {
  font-size: 27px;
}

.fs-28 {
  font-size: 28px;
}

.fs-29 {
  font-size: 29px;
}

.fs-30 {
  font-size: 30px;
}

.fs-31 {
  font-size: 31px;
}

.fs-32 {
  font-size: 32px;
}

.fs-33 {
  font-size: 33px;
}

.fs-34 {
  font-size: 34px;
}

.fs-35 {
  font-size: 35px;
}

.fs-36 {
  font-size: 36px;
}

.fs-37 {
  font-size: 37px;
}

.fs-38 {
  font-size: 38px;
}

.fs-39 {
  font-size: 39px;
}

.fs-40 {
  font-size: 40px;
}

.fs-41 {
  font-size: 41px;
}

.fs-42 {
  font-size: 42px;
}

.fs-43 {
  font-size: 43px;
}

.fs-44 {
  font-size: 44px;
}

.fs-45 {
  font-size: 45px;
}

.fs-46 {
  font-size: 46px;
}

.fs-47 {
  font-size: 47px;
}

.fs-48 {
  font-size: 48px;
}

.fs-49 {
  font-size: 49px;
}

.buy-1-click-cloned.transformed > input {
  max-width: 100% !important;
}

.color-orange,
.color-orange-hover span:hover,
.color-orange-hover a:hover {
  color: #f37021;
}

.head_title {
  background-color: white;
  top: 3.83rem;
  z-index: 5;
  display: grid;
  align-items: center;
  position: sticky;
}

.head_title h1 {
  margin: 0;
}

.like-in-img i {
  font-size: 26px !important;
}

.like-in-img {
  top: 0;
  position: absolute !important;
  margin-top: 80%;
  right: 9px;
  align-items: center;
  align-content: center;
  background-color: transparent;
  font-size: 26px !important;
}

.stAddToCart {
  color: #f37021 !important;
  font-weight: bold !important;
  background-color: #fff !important;
}

.carousel-indicators {
  z-index: 2 !important;
}

.leo-swiper-button-next {
  align-items: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  position: absolute;
  top: 50%;
  right: 1%;
  z-index: 1000;
  /*background-color: RGBA(0,0,0,0.28);
  padding: 0.5rem;*/
}

.leo-swiper-button-prev {
  position: absolute;
  top: 50%;
  left: 1%;
  margin-top: calc(0px - var(--swiper-navigation-size) / 2);
  z-index: 1000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  /*	background-color: RGBA(0,0,0,0.28);
      padding: 0.5rem;*/
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 8px #f37021, inset 0 0 8px #f37021;
  }
  50% {
    box-shadow: 0 0 16px #f37021, inset 0 0 14px #f37021;
  }
  100% {
    box-shadow: 0 0 8px #f37021, inset 0 0 8px #f37021;
  }
}
.modal__voice {
  position: fixed;
  top: 0;
  z-index: 99900;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.73);
  display: none;
  align-items: center;
  align-content: center;
}

.message_voice {
  font-size: 24px;
  font-weight: 700;
  z-index: 9999;
  color: #f37021;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.voice_box {
  background-color: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  box-shadow: 0 0 8px #f37021, inset 0 0 8px #f37021;
  -webkit-animation: pulse 2s linear 1s infinite;
}

.voice_box {
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 100%;
  text-align: center;
}

.loader_voice {
  margin: 0 0 2em;
  text-align: center;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
}

/*
  Set the color of the icon
*/
svg path,
svg rect {
  fill: #FF6700;
}

@media (min-width: 576px) {
  .font-sm {
    font-size: 0.8em;
  }
  .position-sm-static {
    position: static !important;
  }
  .position-sm-relative {
    position: relative !important;
  }
  .position-sm-absolute {
    position: absolute !important;
  }
  .position-sm-fixed {
    position: fixed !important;
  }
  .position-sm-sticky {
    position: sticky !important;
  }
}
@media (min-width: 768px) {
  .font-md {
    font-size: 0.85em;
  }
  .position-md-static {
    position: static !important;
  }
  .position-md-relative {
    position: relative !important;
  }
  .position-md-absolute {
    position: absolute !important;
  }
  .position-md-fixed {
    position: fixed !important;
  }
  .position-md-sticky {
    position: sticky !important;
  }
}
@media (min-width: 992px) {
  .font-lg {
    font-size: 0.9em;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
}
@media (min-width: 1200px) {
  .font-xl {
    font-size: 1em;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
}
@media (max-width: 600px) {
  .leo-icon.s-sm-100 {
    width: 100px !important;
    height: 100px !important;
  }
}
@media (min-width: 768px) {
  .leo-icon.s-md-100 {
    width: 100px !important;
    height: 100px !important;
  }
}
@media (min-width: 1024px) {
  .leo-icon.s-lg-100 {
    width: 100px !important;
    height: 100px !important;
  }
}
.iti {
  width: 100%;
  position: relative;
  display: inline-block;
}

.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.iti__hide {
  display: none;
}

.iti__v-hide {
  visibility: hidden;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}

.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}

.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}

.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}

@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}

.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}

.iti__country {
  padding: 5px 10px;
  outline: none;
}

.iti__dial-code {
  color: #999;
}

.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}

.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}

.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}

.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}

.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}

.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}

.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}

.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}

.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}

.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}

.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  width: 20px;
}

.iti__flag.iti__be {
  width: 18px;
}

.iti__flag.iti__ch {
  width: 15px;
}

.iti__flag.iti__mc {
  width: 19px;
}

.iti__flag.iti__ne {
  width: 18px;
}

.iti__flag.iti__np {
  width: 13px;
}

.iti__flag.iti__va {
  width: 15px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}

.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}

.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}

.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}

.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}

.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}

.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}

.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}

.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}

.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}

.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}

.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}

.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}

.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}

.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}

.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}

.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}

.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}

.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}

.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}

.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}

.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}

.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}

.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}

.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}

.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}

.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}

.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}

.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}

.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}

.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}

.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}

.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}

.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}

.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}

.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}

.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}

.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}

.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}

.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}

.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}

.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}

.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}

.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}

.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}

.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}

.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}

.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}

.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}

.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}

.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}

.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}

.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}

.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}

.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}

.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}

.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}

.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}

.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}

.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}

.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}

.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}

.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}

.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}

.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}

.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}

.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}

.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}

.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}

.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}

.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}

.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}

.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}

.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}

.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}

.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}

.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}

.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}

.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}

.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}

.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}

.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}

.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}

.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}

.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}

.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}

.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}

.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}

.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}

.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}

.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}

.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}

.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}

.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}

.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}

.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}

.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}

.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}

.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}

.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}

.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}

.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}

.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}

.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}

.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}

.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}

.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}

.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}

.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}

.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}

.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}

.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}

.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}

.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}

.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}

.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}

.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}

.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}

.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}

.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}

.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}

.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}

.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}

.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}

.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}

.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}

.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}

.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}

.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}

.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}

.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}

.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}

.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}

.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}

.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}

.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}

.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}

.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}

.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}

.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}

.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}

.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}

.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}

.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}

.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}

.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}

.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}

.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}

.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}

.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}

.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}

.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}

.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}

.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}

.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}

.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}

.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}

.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}

.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}

.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}

.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}

.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}

.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}

.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}

.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}

.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}

.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}

.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}

.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}

.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}

.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}

.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}

.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}

.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}

.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}

.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}

.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}

.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}

.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}

.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}

.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}

.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}

.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}

.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}

.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}

.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}

.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}

.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}

.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}

.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}

.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}

.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}

.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}

.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}

.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}

.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}

.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}

.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}

.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}

.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}

.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}

.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}

.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}

.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}

.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}

.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}

.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}

.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}

.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}

.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}

.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}

.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}

.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}

.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}

.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}

.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}

.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}

.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}

.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}

.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}

.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}

.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}

.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}

.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}

.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}

.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}

.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}

.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}

.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}

.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}

.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}

.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}

.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}

.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}

.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}

.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}

.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}

.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}

.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}

.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}

.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}

.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}

.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}

.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}

.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}

.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}

.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}

.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}

.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}

.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}

.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}

.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}

.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}

.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}

.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}

.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}

.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}

.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags@2x.png");
  }
}
.iti__flag.iti__np {
  background-color: transparent;
}

.form_test {
  display: grid;
  margin-bottom: 1.56vw;
}

.form_mask1 {
  width: 100px;
  justify-content: center;
  text-align: start;
}

.form_mask2 {
  display: grid;
  justify-content: center;
  text-align: start;
}

.input_form {
  border: 1px solid #bebebe;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.br-0 {
  border-radius: 0% !important;
}

.br-5 {
  border-radius: 5% !important;
}

.br-10 {
  border-radius: 10% !important;
}

.br-15 {
  border-radius: 15% !important;
}

.br-20 {
  border-radius: 20% !important;
}

.br-25 {
  border-radius: 25% !important;
}

.br-30 {
  border-radius: 30% !important;
}

.br-35 {
  border-radius: 35% !important;
}

.br-40 {
  border-radius: 40% !important;
}

.br-45 {
  border-radius: 45% !important;
}

.br-50 {
  border-radius: 50% !important;
}

.page-product-box .quantity-input {
  max-width: 80px !important;
}

.br-none {
  border-radius: 0 !important;
}

.b24-widget-button-popup-image {
  width: 60px !important;
}

.b24-widget-button-popup-image-item {
  width: 60px !important;
  height: 60px !important;
}

.text-dark-leo {
  color: #414141 !important;
}

.leo-swiper-button-next, .leo-swiper-button-prev {
  background: none !important;
  background-color: unset !important;
}

:root {
  --main-orange: #f37021 ;
}

.btn-orange-outline-dark-text {
  min-width: 18vw;
  text-align: center;
  color: black;
  background-color: #fff;
  font-weight: normal;
  border: 2px solid #f37021 !important;
}

.btn-orange-outline-dark-text:hover {
  color: #fff;
  background-color: #f37021;
}

.btn-orange-outline {
  color: #f37021;
  background-color: #fff;
  font-weight: bold;
}

.btn-orange-outline:hover {
  color: #fff;
  background-color: #f37021;
}

.btn-orange-outline i {
  color: #f37021;
}

.btn-orange-outline:hover i {
  color: #f0f0f0;
}

#category-list-body-products {
  min-height: 70vh;
}

.nav-item.black-friday, .nav-item.black-friday > .nav-link {
  background-color: black !important;
  color: white !important;
}

.nav-item.black-friday :hover {
  background-color: black !important;
  color: white !important;
}

.corner.black-friday, .cornerNew.black-friday {
  background-color: black !important;
  color: white !important;
  width: 95% !important;
}

.corner-black-friday {
  color: #fff;
  background-color: black;
  height: 25px;
  text-transform: uppercase;
}

.corner-black-percent {
  background-color: var(--main-orange);
  height: 25px;
  font-size: 1.1em;
  color: #fff;
}

.corner {
  position: absolute;
  max-width: 100%;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  top: 5px;
  left: 0;
  z-index: 9;
}

@media only screen and (max-width: 1023px) {
  .corner {
    font-size: 2.5vw;
  }
}
.left-0 {
  left: 0 !important;
}

.new-year-corner {
  position: absolute;
  display: flex;
  width: 100%;
  top: 5px;
  left: 0;
  z-index: 1;
}
.new-year-corner .new-year-corner-item {
  color: white;
  background-color: var(--main-orange);
  font-weight: bold;
  font-size: 0.8rem;
}
@media only screen and (max-width: 1023px) {
  .new-year-corner .new-year-corner-item {
    font-size: 2.9vw;
  }
}
.new-year-corner .new-year-corner-line {
  inset: 0;
  width: 2px;
  background: white;
}

.new-year-menu {
  background-color: var(--main-orange);
}

.navbar-top > ul > li {
  height: 35px;
}

.new_year_class {
  background-color: var(--main-orange);
  color: white !important;
  font-weight: bold;
}
.new_year_class:hover {
  color: white !important;
}